//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mockSrv from "@/api/mockSrv";
export default {
  components: {
    Header: () => import("@/components/Header"),
    CContent: () => import("@/components/CContent"),
    CTitle: () => import("@/components/CTitle"),
    CPages: () => import("@/components/CPages"),
  },
  data: () => ({
    isManager: false,
    mockData: false,
    loading: false,
    search: {
      searchText: "",
      paymentStatus: null,
      stime: null,
      etime: null,
    },
    tableData: [],
    titleInfo: {
      btnShow: [
        { type: "addBtn", ishow: false, disabled: true },
        { type: "startBtn", ishow: false, disabled: true },
        { type: "closeBtn", ishow: false, disabled: true },
        { type: "delBtn", ishow: false, disabled: true },
      ],
      dropDown: {
        drop_2: {
          placeholder: "订单状态",
          clear: true,
          options: [
            {
              label: "等待付款",
              value: "0",
            },
            {
              label: "已付款",
              value: "1",
            },
            {
              label: "已退款",
              value: "3",
            },
          ],
        },
        drop_3: {
          placeholder: "请选择时间范围",
          clear: true,
        },
        searchInput: {
          placeholder: "请输入订单编号",
        },
      },
    },
    pageData: {
      pageIndex: 1,
      pageSize: 10,
      totalNum: 0,
    },
  }),
  methods: {
    orderDiscountInfo(order) {
      var info = "";
      if (order) {
        if (order.DiscountInfo) {
          info += order.DiscountInfo.split("\r\n")[0];
        }
        order.Products.forEach((m) => {
          if (m.DiscountInfo) {
            info += m.DiscountInfo.split("\r\n")[0] + "\r\n";
          }
        });
      }
      info = info.replace(/\r\n/g, "<br/>");
      return info;
    },
    getPaymentMethod(row, column) {
      return this.$D.ITEM("payment_method", row.PaymentMethod).key;
    },
    getPaymentStatus(row, column) {
      return this.$D.ITEM("payment_status", row.PaymentStatus).key;
    },
    getOrderStatus(row, column) {
      return this.$D.ITEM("order_status", row.OrderStatus).key;
    },
    getProductInfo(productSkus) {
      var productInfo = "";
      var skus = productSkus.map(
        (m) => m.ProductInfo + "货品数量：" + m.Count + m.Unit
      );
      for (let index = 0; index < skus.length; index++) {
        const sku = skus[index];
        productInfo += sku.replace(/-1/g, "永久有效");
        if (index != skus.length - 1) {
          productInfo += "\r\n";
        }
      }
      return productInfo;
    },
    drop2Change(paymentStatus) {
      if (paymentStatus == -1 || paymentStatus == "") {
        paymentStatus = null;
      }
      this.pageData.pageIndex = 1;
      this.search.paymentStatus = paymentStatus;
    },
    drop3Change(val) {
      this.pageData.pageIndex = 1;
      let stime = null;
      let etime = null;
      stime = val[0] == undefined ? null : val[0];
      etime = val[1] == undefined ? null : val[1];
      if (stime && etime) {
        this.search.etime = new Date(
          Math.max(new Date(stime).getTime(), new Date(etime).getTime())
        ).timeFormat("yyyy-MM-dd");
        this.search.stime = new Date(
          Math.min(new Date(stime).getTime(), new Date(etime).getTime())
        ).timeFormat("yyyy-MM-dd");
      } else {
        this.search.stime = stime;
        this.search.etime = null;
      }
    },
    searchClick(search) {
      this.search.searchText = search;
      this.getDataList();
    },
    handlePaginationChange(val) {
      this.pageData = val;
      this.getDataList();
    },
    getDataList() {
      this.loading = true;
      var params = {
        searchText: this.search.searchText,
        paymentStatus: this.search.paymentStatus,
        stime: this.search.stime,
        etime: this.search.etime,
        total: 200,
        pageIndex: this.pageData.pageIndex,
        pageSize: this.pageData.pageSize,
      };
      if (this.mockData) {
        mockSrv.orderList(params).then((resp) => {
          if (resp.res == 0) {
            this.tableData = resp.data.Data;
            this.pageData.totalNum = resp.data.Total;
          }
        });
      } else {
        this.$http
          .post("/Order/List.ashx", params)
          .then((resp) => {
            if (resp.res == 0) {
              this.tableData = resp.data.Data;
              this.pageData.totalNum = resp.data.Total;
            }
          })
          .catch((err) => {
            this.$message({
              message: `获取订单列表失败`,
              type: "error",
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    deleteOrder(order) {
      this.$confirm("此操作将永久删除该商品, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.loading = true;
          var params = {
            Id: order.Id,
          };
          this.$http
            .post("/Order/Delete.ashx", params)
            .then((resp) => {
              if (resp.res == 0) {
                this.$message({
                  message: `删除商品成功`,
                  type: "success",
                });
                this.getDataList();
              }
            })
            .catch((err) => {
              this.$message({
                message: `删除商品失败`,
                type: "error",
              });
            })
            .finally(() => {
              this.loading = false;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    addRemarks(order) {
      this.$prompt("请输入备注信息", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputType: "textarea",
        inputValidator: (value) => {
          if (value.length > 1000) {
            return "备注信息最多2000字";
          }
          return true;
        },
      }).then(({ value }) => {
        this.loading = true;
        var params = {
          Id: order.Id,
          remarks: value,
        };
        this.$http
          .post("/Order/Remarks.ashx", params)
          .then((resp) => {
            if (resp.res == 0) {
              this.$message({
                message: `修改备注成功`,
                type: "success",
              });
              this.getDataList();
            }
          })
          .catch((err) => {
            this.$message({
              message: `修改备注失败`,
              type: "error",
            });
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    markRefund(order) {
      this.$prompt("请输入退款金额", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputType: "number",
        inputValue: order.PayAmount,
        inputPattern: /^\d+(\.\d+)?$/,
        inputValidator: (value) => {
          if (value > order.PayAmount) {
            return "退款金额不能高于实付金额";
          }
          return true;
        },
      }).then(({ value }) => {
        this.loading = true;
        var params = {
          Id: order.Id,
          amount: value,
        };
        this.$http
          .post("/Order/MarkRefund.ashx", params)
          .then((resp) => {
            if (resp.res == 0) {
              this.$message({
                message: `标记退款成功`,
                type: "success",
              });
              this.getDataList();
            }
          })
          .catch((err) => {
            this.$message({
              message: `标记退款失败`,
              type: "error",
            });
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    applyRefund(order) {
      this.$prompt("请输入退款金额", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputType: "number",
        inputValue: order.PayAmount,
        inputPattern: /^\d+(\.\d+)?$/,
        inputValidator: (value) => {
          if (value > order.PayAmount) {
            return "退款金额不能高于实付金额";
          }
          return true;
        },
      }).then(({ value }) => {
        this.loading = true;
        var params = {
          Id: order.Id,
          amount: value,
        };
        this.$http
          .post("/Order/MarkRefund.ashx", params)
          .then((resp) => {
            if (resp.res == 0) {
              this.$message({
                message: `标记退款成功`,
                type: "success",
              });
              this.getDataList();
            }
          })
          .catch((err) => {
            this.$message({
              message: `标记退款失败`,
              type: "error",
            });
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    payOrder(order) {
      this.loading = true;
      const baseURL = window.location.protocol + "//" + window.location.host;
      var params = {
        orderId: order.Id,
        returnUrl: baseURL + "/orderHistory",
      };
      this.$http
        .post("/Order/PayOrder.ashx", params)
        .then((resp) => {
          if (resp.res == 0) {
            let html = '<div style="display:none">';
            html += resp.data;
            html += "</div>";
            window.document.write(html);
          } else {
            this.$message({
              message: `获取订单失败`,
              type: "error",
            });
          }
        })
        .catch((err) => {
          this.$message({
            message: `获取订单失败`,
            type: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getDataList();
  },
};
